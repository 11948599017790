import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../components/__common/Loading/Loading";
import { URLS } from "../../../constants/urls";
import { useAuth } from "../../../hooks/auth-hooks";
import { useQuery } from "../../../hooks/query-hooks";
import { ApplicationState } from "../../../store";
import { UserState } from "../../../store/reducers/user-reducer";
import { useHistory } from "react-router-dom";
import { IS_PRE, IS_PROD } from "../../../constants/environment";

export const Login: React.FC = () => {
  const query: any = useQuery();
  const { login, loginV1 } = useAuth();
  const history = useHistory();
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  useEffect(() => {
    const body = document.body;
    body.classList.add("modal-open");

    return () => {
      body.classList.remove("modal-open");
    };
  });

  useEffect(
    () => {
      if (!userState.isGeoIPLoading) {
        let redirectUri = query.get("redirect");

        if (redirectUri && redirectUri.includes(URLS.OLD_MYDATA)) {
          redirectUri = URLS.MYDATA;
        }

        if (!redirectUri) {
          localStorage.setItem("hasAccessLoginThruDeepLinked", "true");
          history.push("/");
        } else {
          if (IS_PRE || IS_PROD) {
            loginV1(redirectUri);
          } else {
            login(redirectUri);
          }
        }
      }
    },
    // eslint-disable-next-line
    [userState.isGeoIPLoading]
  );

  return <Loading />;
};
